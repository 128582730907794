:root {
  --lighter-blue: #9fe9ecd2;
  --org-blue: rgba(107, 160, 222);
  --light-blue: #6fb4c0;
  --meta-blue: rgb(56, 117, 234);
  --light-green: #c3ffa2;
  --blue: #1d2533ed;
  --dark-blue: #0c2659d9;
  --dark-blue-filter: invert(40%) sepia(11%) saturate(3516%) hue-rotate(183deg) brightness(94%) contrast(77%);
  --light-orange: #f1a665;
  --dark-orange: #da8337;
  --light-grey: #e8e8e87b;
  --greyish: #3f3f3fc9;
  --light-bg: #ffffff;
  /* --dark-bg: #282c34; */
  --dark-bg: #0d1020;
  /* --darker-bg: #1f2228d1; */
  --darker-bg: #191b23;
  --darkerr-bg: #16191e;

  --background-color: var(--light-bg);
  --selection-color: var(--org-blue);
  --selection-text: var(--light-bg);
  --footer-bg: var(--light-bg);
  --footer-text: var(--dark-blue);
  --navbar-title-color: var(--dark-blue);
  --navbar-hover-underline: var(--dark-blue);
  --navbar-mid-hover-underline: var(--light-blue);
  --text-color: var(--greyish);
  --navbar-color-at-top: #ffffffa2;
  /* --navbar-color-at-mid: var(--blue); */
  --navbar-color-at-mid: #1d2533d7;
  --anchor-color: var(--dark-orange);
  --active-color: #d77f33;
  --active-color-mid: #ff9900;
  --hover-color: var(--dark-blue);
  --copyright-bg: var(--blue);
  --copyright-hover: var(--light-orange);
  --menu-highlight: #2525257d;
  --menu-filter: var(--dark-blue-filter);
  --org-color: var(--meta-blue);
  --text-input-color: var(--dark-bg);
  --textarea-color: var(--light-bg);
  --textarea-focus-color: #d9e4f5;
  --btn-bg: radial-gradient(circle at 25% 25%, hsla(207, 59%, 46%, 0.917) 0, hsla(208, 70%, 43%, 0.898) 100%);
  --btn-shadow: 0 8px 0 hsl(212, 67%, 33%), 2px 12px 10px rgba(8, 42, 63, 0.35);
  --button-hover: var(--dark-blue);
  --text-underline: var(--blue);
}

.dark-mode {
  /* :root { */
  --background-color: var(--dark-bg);
  --selection-color: #506fd2c4;
  --selection-text: none;
  /* --footer-bg: linear-gradient(to bottom, var(--dark-bg), #1b1e24); */
  --footer-bg: whatever;
  --footer-text: var(--light-bg);
  --navbar-title-color: var(--light-bg);
  --navbar-hover-underline: var(--light-blue);
  --text-color: var(--light-bg);
  /* --navbar-color-at-top: var(--dark-bg); */
  --navbar-color-at-top: #0d1020b9;
  /* --navbar-color-at-mid: var(--darker-bg); */
  --navbar-color-at-mid: #191b23b9;
  --anchor-color: #e28a4c;
  --active-color: #ff9900;
  --active-color-mid: #ff9900;
  --hover-color: var(--lighter-blue);
  /* --copyright-bg: var(--darker-bg); */
  --copyright-bg: ok;
  --copyright-hover: var(--light-orange);
  --menu-highlight: #0b0b0b7d;
  --menu-filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(86deg) brightness(107%) contrast(107%);
  --org-color: var(--org-blue);
  --textarea-color: var(--dark-bg);
  --textarea-focus-color: var(--darker-bg);
  --btn-bg: radial-gradient(circle at 25% 25%, hsla(227, 45%, 16%, 1) 0, hsla(224, 71%, 19%, 1) 100%);
  --btn-shadow: 0 8px 0 hsl(231, 57%, 9%), 2px 12px 10px rgba(11, 11, 41, 0.35);
  --button-hover: var(--darkerr-bg);
  --text-underline: var(--lighter-blue);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", Verdana, Rockwell, "Comic Sans MS", "Arial Rounded MT Bold", Menlo, Monaco, "Courier New", monospace;
}

body {
  min-height: 100vh;
  width: 100%;
  background-size: auto;
  overflow-x: hidden;
  background-color: var(--background-color);
  background-image: url("./particles.png");
  position: absolute;
  color: var(--text-color);
  z-index: -1;
}

.about-content p,
h4,
h5,
h6,
.org,
address,
.form {
  animation: slide-up 0.5s ease;
}

@keyframes slide-up {
  0% {
    transform: translateY(160px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
  }
}

::selection {
  background: var(--selection-color);
  color: var(--selection-text);
}

a::selection {
  color: var(--light-green);
}

h2,
h3,
p {
  margin: 0.5rem 0;
}

.project-content {
  margin-left: 1rem;
  margin-right: 1rem;
}

.project-description,
.project-content .org {
  font-size: medium;
}

.tools {
  font-size: medium;
  margin: 0;
  padding: 0;
}

p,
.form-label {
  font-size: large;
}

h1,
h2,
h3,
.copyright p,
.copyright a,
.nav-link {
  font-weight: bolder;
  font-family: Montserrat;
}

h4 {
  font-family: Montserrat;
  margin-top: 1rem;
}

h6 {
  font-size: small;
}

.about-title {
  margin-bottom: 1rem;
}

.project-title {
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

footer h3 {
  color: var(--footer-text);
}

section {
  padding-bottom: 5rem;
}

.contact {
  padding: 1rem;
}

.campus-title {
  padding-top: 3rem;
}

ul {
  padding: 0;
}

.mobile-nav-toggle {
  display: none;
}

a {
  text-decoration: none;
}

header {
  background-position: center;
  background-size: cover;
  display: flex;
}

.navbar {
  background-color: var(--navbar-color-at-top);
  transition: all 0.5s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  color: var(--dark-blue);
  position: fixed;
  width: 100%;
  box-shadow: 0px -20px 5px 20px #333333;
  z-index: 100;
}

.navbar-title {
  margin: 1rem 2rem;
  color: var(--navbar-title-color);
}

.navbar ul {
  display: flex;
  list-style: none;
  margin: 0 2rem 0 0;
}

.navbar ul li {
  padding: 1rem 1rem 0.5rem;
}

.nav-link {
  color: var(--navbar-title-color);
}

.nav-link::after {
  content: "";
  width: 0%;
  height: 0.3rem;
  background: var(--navbar-hover-underline);
  display: block;
  margin: auto;
  transition: 0.3s;
}

.navbar-mid .nav-link::after {
  background: var(--navbar-mid-hover-underline);
}

.nav-link:hover::after,
.active::after {
  width: 100%;
  transition: 0.3s;
}

.navbar-mid {
  background-color: var(--navbar-color-at-mid);
  backdrop-filter: blur(0.2rem);
}

.navbar-mid h3,
.navbar-mid .nav-link {
  color: var(--light-bg);
}

.nav-link:hover,
.navbar .active {
  color: var(--active-color);
  transition: 0.3s;
}

.navbar-mid .nav-link:hover,
.navbar-mid .active {
  color: var(--active-color-mid);
}

.container {
  display: flex;
  height: 100%;
  flex-direction: column;
  flex-wrap: wrap;
  padding-top: 8rem;
  margin-bottom: 4rem;
}

.row {
  justify-content: space-around;
}

.portrait,
.blur-img {
  width: 180px;
  height: 230px;
  border-radius: 10px;
  vertical-align: middle;
}

.blur-img,
.blur-img-project {
  background-size: cover;
  background-position: center;
}

.text-under-portrait {
  margin-bottom: 30px;
}

.about-content a,
.contact-info a,
.back {
  color: var(--anchor-color);
}

.blur-img-project:hover {
  transform: scale(1.05);
  transition: all 0.3s ease;
}

.fa {
  padding: 20px;
  font-size: 25px;
  width: 65px;
  margin: 5px 4px;
  border-radius: 62px;
}

.fa.fa-threads {
  margin: 4px;
}

.fa-envelope {
  background: #3d94eb;
}

.fa-facebook {
  background: #3b5998;
}

.fa-twitter {
  background: #55acee;
}

.fa-linkedin {
  background: #007bb5;
}

.fa-instagram {
  /* background: linear-gradient(145deg, #3051F1 10%, #C92BB7 70%) no-repeat; */
  background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%),
    radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%),
    radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
}

.fa-github {
  background: #0d2028;
}

.fa-threads {
  background: #f1a665;
}

.github-in-line,
.fa-link {
  width: 10px;
  margin: 0 10px;
  padding: 0px;
  border-radius: 50%;
  background: none;
}

.material-symbols-outlined {
  font-size: 2rem;
  position: relative;
  top: 5px;
}

footer {
  padding-top: 1rem;
  background: var(--footer-bg);
  color: var(--light-bg);
  text-align: center;
}

.links {
  list-style: none;
  display: flex;
  justify-content: center;
  margin: 2rem 0;
}

.links a {
  color: var(--light-bg);
  font-size: 1rem;
  transition: 0.3s;
}

.links a:hover {
  color: var(--dark-bg);
  transition: 0.3s;
}

.text-underline {
  display: inline-block;
  position: relative;
}

.text-underline:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--text-underline);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.text-underline:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.copyright {
  background: var(--copyright-bg);
  padding: 0.5rem;
  text-align: center;
}

.copyright a {
  color: white;
}

a:hover {
  opacity: 0.7;
  color: var(--hover-color);
  transition: 0.2s;
}

.copyright a:hover {
  color: var(--copyright-hover);
  transition: 0.3s;
}

.slope:before {
  transform: rotate(-2.5deg) translateZ(0);
}

.org,
.tools {
  color: var(--org-color);
}

.align-items-stretch {
  margin-top: 30px;
}

.form {
  display: flex;
  flex-direction: column;
  max-width: 900px;
  margin: 0 auto;
}

input,
textarea {
  border: 1px solid #ccc;
}

.float-label {
  margin-bottom: 0;
}

.floating-label label,
.form-control::placeholder {
  color: var(--placeholder-color);
  opacity: 0.7;
}

textarea:focus::placeholder {
  color: transparent;
  transition: color 0.3s ease;
}

.form-control:focus {
  color: var(--footer-text);
  background-color: var(--textarea-focus-color);
}

.form-control {
  color: var(--footer-text);
  background-color: var(--textarea-color);
}

.form-button {
  margin: 8px 6px;
  height: 2.5em;
  border-radius: 10px;
  background: var(--btn-bg);
  box-shadow: var(--btn-shadow);
  color: white;
  text-shadow: -1px -1px 2px rgba(0, 0, 0, 0.3), 1px 1px 2px rgba(255, 255, 255, 0.4);
  text-transform: uppercase;
  letter-spacing: 0.05em;
  transform: translate(0, -8px);
  transition: all 0.2s;
}

.form-button:active {
  transform: translate(0, -2px);
  box-shadow: 0 2px 0 hsl(235, 75%, 9%), 2px 6px 10px rgba(0, 0, 0, 0.35);
}

.form-button:focus {
  color: white;
  background-color: var(--darkerr-bg);
}

.form-button:hover {
  color: var(--text-underline);
  background-color: var(--button-hover);
}

button.sent {
  animation: fadeOut 1s ease 1 forwards;
}

.success-message {
  color: var(--footer-text);
  padding: 8px 12px;
}

.leave-message-title {
  margin-bottom: 25px;
}

.form-group {
  margin: 0 8px;
}

@media (max-width: 965px) {
  body {
    position: inherit;
  }
}

@media (max-width: 35em) {
  .navbar-links {
    position: fixed;
    z-index: 1000;
    inset: 0 0 0 30%;
    flex-direction: column;
    padding: min(30vh, 10rem) 2rem;
    background: hsl(0 0% 0% / 0.5);
    backdrop-filter: blur(0.5rem);
    transform: translateX(100%);
    transition: transform 300ms ease;
  }

  /* @supports (backdrop-filter: blur(.5rem)) {
				.navbar-links {
						background: hsl(0 0% 100% / .1);
						backdrop-filter: blur(.5rem);
				}
		} */

  .navbar-mid {
    backdrop-filter: unset;
  }

  .nav-link {
    color: var(--light-bg);
  }

  .nav-link:hover,
  .navbar .active,
  .active {
    color: var(--active-color);
  }

  #primary-nav li {
    background-color: var(--menu-highlight);
    padding-bottom: 0.5rem;
    margin: 1rem;
  }

  .nav-link:hover::after,
  .active::after {
    width: 0;
  }

  .navbar ul {
    margin: 0;
  }

  .contact-info {
    text-align: center;
  }

  .about-title,
  .campus-title,
  .contact-content {
    padding-top: 2rem;
  }

  .campus-title {
    text-align: left;
  }

  .contact-info p {
    padding-left: 0;
  }

  section {
    padding-bottom: 2rem;
  }

  .container {
    padding-right: 30px;
    padding-left: 30px;
    margin-right: auto;
    margin-left: auto;
  }

  /* needed to force it to the middle for mobile view only */
  .blur-img {
    margin: 0 auto;
  }

  .mobile-nav-toggle {
    display: block;
    position: absolute;
    z-index: 9999;
    background: url("../public/img/icon-menu.svg");
    filter: var(--menu-filter);
    background-repeat: no-repeat;
    border: 0;
    width: 3rem;
    aspect-ratio: 1;
    right: 2rem;
  }

  .navbar-mid .mobile-nav-toggle,
  .expanded {
    filter: invert(99%) sepia(1%) saturate(408%) hue-rotate(174deg) brightness(115%) contrast(100%);
  }

  .showNav {
    transform: translateX(0%);
  }

  .expanded {
    background-image: url("../public/img/icon-close.svg");
  }
}
